@import "_themeVariables";

$layout-breakpoint-small: theme("screens.sm");
$layout-breakpoint-medium: theme("screens.md");
$layout-breakpoint-large: theme("screens.lg");

.header {
    &__illustration {
        svg,
        img {
            height: 100px;
    
            @media (min-width: $layout-breakpoint-medium) {
                height: 250px;
            }
        }
    }
}

.mancave__bg {
    position: absolute;
    width: 145px;
    height: 100%;
    top: 0;
    left: 50%;
    right: 0;
    bottom: 0;
    transform: translateX(-50%);
    background-color: rgba(150,150,150, .5);
    z-index: 0;
    @media (min-width: $layout-breakpoint-medium) {
        width: 355px;
    }
}
